module controllers {
    export module purchase {
        interface IPurchaseScope extends ng.IScope {
            IsLoading: boolean;
            ShowGrid: boolean;
        }

        export class purchaseOrderListCtrl {
            static $inject = ["$scope", 'purchaseOrderService', '$rootScope', 'generalService', '$q', 'entityService', '$state', 'supplierService', 'currencyService',
                'statusService', '$timeout', '$transitions','userAccountService'];

            poSearch: interfaces.purchase.IPurchaseOrderSearch = {
                POStatusId: 0
            };

            searchAccordian: boolean = false;

            fromDateOpen: boolean = false;
            toDateOpen: boolean = false;
            filterDateOpen: boolean = false;

            hasCreateFromChildOrderRight : boolean = false;
            
            public searchSupplier: interfaces.applicationcore.IDropdownModel = null;
            public searchCurrency: interfaces.applicationcore.IDropdownModel = null;

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            dropdownsObject = {
                Entities: Array<interfaces.applicationcore.IEntity>()
            };

            statusList: interfaces.applicationcore.IDropdownModel[] = [];
            
            apiPOList: uiGrid.IGridApi;
            selectedPO: uiGrid.IGridRow;

            filterNames: string[] = ["OwnerEntityCode", "OrderNumber", "OrderDate",
                "SupplierName", "TypeName", "CurrencyCode",
                "IncotermCode", "Status", "SLA", "Origin", "TransportMode", "LSP", "LatestHandoverDate","LastestETA"];
            filterList: interfaces.applicationcore.IKeyValue[] = [];

            purchaseOrderHook: any;

            sortName: string;
            sortDirection: string;

            constructor(private $scope: IPurchaseScope, private purchaseOrderService: interfaces.purchase.IPurchaseOrderService,
                private $rootScope: interfaces.applicationcore.IRootScope, public generalService: interfaces.applicationcore.IGeneralService, private $q: ng.IQService,
                private entityService: interfaces.applicationcore.IEntityService, private $state: ng.ui.IStateService,
                private supplierService: interfaces.master.ISupplierService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private statusService: interfaces.master.IStatusService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private userAccountService: interfaces.applicationcore.IUserAccountService
            ) {

                this.loadControls();

                this.purchaseOrderHook = $transitions.onSuccess({
                    to: 'auth.PurchaseOrders',
                    from: 'auth.PurchaseOrders.**'
                }, () => {
                    return this.loadControls();
                });
            }

            loadControls() {
                this.$scope.ShowGrid = false;

                let controlPromises = [
                    this.loadEntities(),
                    this.loadStatuses(),
                    this.getCreateFromChildOrderRight()
                ]
                
                this.$q.all(controlPromises).then((data) => {
                    this.$scope.ShowGrid = true;
                    this.DoSearch();
                });

            }

            getCreateFromChildOrderRight() {
                this.hasCreateFromChildOrderRight = false;

                return this.userAccountService.hasRight(637).get((result) => {
                    if (result) {
                        this.hasCreateFromChildOrderRight = true;
                    }
                }).$promise;
            }

            loadEntities(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getList().query((successList) => {
                    this.dropdownsObject.Entities = successList;
                    deferred.resolve(true);
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            search_click() {
                this.$scope.ShowGrid = true;
                this.$scope.IsLoading = true;
                this.searchAccordian = false;
                this.DoSearch();
            }

            showAll_click() {
                this.$scope.ShowGrid = true;
                this.$scope.IsLoading = true;
                this.searchAccordian = false;
                this.searchSupplier = null;
                this.poSearch = { }

                if (this.apiPOList !== undefined) {
                    this.apiPOList.grid.clearAllFilters(true, true, true).then(() => {
                        this.apiPOList.grid.resetColumnSorting(null);
                        this.DoSearch();
                    });
                } else {
                    this.DoSearch();
                }
            }

            downloadToExcel() {
                this.$scope.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.poSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiPOList) {

                    var grid = this.apiPOList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                var params = {
                    connectSearch: searchObject
                }

                this.purchaseOrderService.getPurchaseOrderListExcel(params).then(() => {
                    this.$scope.IsLoading = false;
                }, (data) => {
                    this.$scope.IsLoading = false;
                    this.gvwPOList.data = [];
                    this.gvwPOList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.apiPOList.grid.getColumn(name).filters[0].term || this.apiPOList.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiPOList.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiPOList.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.apiPOList.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    var column = this.apiPOList.grid.getColumn(this.sortName);

                    if (column.sort.direction != this.sortDirection) {
                        this.apiPOList.grid.sortColumn(column, this.sortDirection);
                    }

                    this.apiPOList.grid.getColumn(this.sortName).sort.direction = this.sortDirection;
                }
            }
            
            gvwPOList: uiGrid.IGridOptions = {
                    data: [],
                    enableFiltering: true,
                    useExternalFiltering: true,
                    enableCellEdit: false,
                    useExternalSorting: true,
                    multiSelect: false,
                    enableColumnResizing: true,
                    enableRowSelection: true,
                    enableFullRowSelection: true,
                    paginationPageSizes: [25, 50, 75, 100],
                    paginationPageSize: 25,
                    useExternalPagination: true,
                    enableRowHeaderSelection: false,
                    enableHorizontalScrollbar: 2,
                    enableVerticalScrollbar: 1,
                    onRegisterApi: (gridApi) => {
                    this.registerGridApi(gridApi);

                    this.apiPOList.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiPOList.selection.selectRow(newCol.row.entity);
                        });
                    });
                },
                rowTemplate: `<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, 'SLA-amber': row.entity.SLAStatus === 1, 'SLA-danger': row.entity.SLAStatus === 2 }" ui-grid-cell></div>`,
                    columnDefs: [{
                        name: "ID",
                        displayName: "ID",
                        field: "Id",
                        visible: false,
                        enableFiltering: false
                    }, {
                        name: "EDIT",
                        displayName: "",
                        enableFiltering: false,
                        cellTemplate: `
                            <div> 
                                <button type="button" ui-sref="auth.PurchaseOrders.Update({ poId: row.entity.Id })" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        width: 35
                    }, {
                        name: "OwnerEntityCode",
                        displayName: "Entity",
                        field: "OwnerEntityCode",
                        width: 60,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                    }, {
                        name: "OrderNumber",
                        displayName: "PO Number",
                        field: "OrderNumber",
                        width: 250,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }, {
                        name: "OrderDate",
                        displayName: "PO Date",
                        field: "OrderDate",
                        width: 150,
                        type: "date",
                        cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="OrderDate"></gts-date>
                                        
                            </div>`,
                    }, {
                        name: "SupplierName",
                        displayName: "Supplier Name",
                        field: "SupplierName",
                        width: 150,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    }, {
                        name: "TypeName",
                        displayName: "PO Type",
                        field: "TypeName",
                        width: 150,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                    }, {
                        name: "TotalForeignCurrencyValue",
                        displayName: "Total Value",
                        field: "TotalForeignCurrencyValue",
                        type: "number",
                        cellFilter: "number: 5",
                        width: 140,
                        enableFiltering: false,
                    }, {
                        name: "Currency",
                        displayName: "Currency",
                        field: "Currency",
                        width: 100,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                    }, {
                        name: "TotalQuantity",
                        displayName: "Total Quantity",
                        field: "TotalQuantity",
                        type: "number",
                        cellFilter: "number: 2",
                        width: 110,
                        enableFiltering: false,
                    }, {
                        name: "Incoterm",
                        displayName: "Incoterm",
                        field: "Incoterm",
                        width: 100,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                    }, {
                        name: "Status",
                        displayName: "Status",
                        width: 200,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                    },{
                            name: "SLA",
                            displayName: "SLA",
                            field: "SLA",
                            width: 125,
                            type: "date",
                            cellFilter: 'momentDateFilter:"YYYY/MM/DD HH:mm:SS"',
                            filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="SLA"></gts-date>
                                        
                            </div>`
                        },
                        {
                            name: "Origin",
                            displayName: "Origin",
                            width: 100,
                            filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                        },
                        {
                            name: "TransportMode",
                            displayName: "Mode",
                            width: 100,
                            filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                        },
                        {
                            name: "LSP",
                            displayName: "LSP",
                            width: 200,
                            filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                        }, {
                            name: "LatestHandoverDate",
                            displayName: "Latest Handover",
                            field: "LatestHandoverDate",
                            width: 125,
                            type: "date",
                            cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                            filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="LatestHandoverDate"></gts-date>
                                        
                            </div>`,
                        }, {
                            name: "LatestETA",
                            displayName: "Latest ETA",
                            field: "LatestETA",
                            width: 125,
                            type: "date",
                            cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                            filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="LastestETA"></gts-date>
                                        
                            </div>`,
                        }]
                };

            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiPOList = gridApi;

                this.apiPOList.core.on.filterChanged(this.$scope, () => { this.POListFilterChange(gridApi) });
                this.apiPOList.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.POListShortChange(gridApi);

                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });
                this.apiPOList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.POListPageChange(newPage, pageSize) });

                this.apiPOList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    this.$timeout(() => {
                        this.selectedPO = selected;
                    });
                });

                this.$timeout(() => {
                    if (this.selectedPO)
                        this.apiPOList.selection.selectRow(this.selectedPO.entity);
                });
            }

            POListPageChange(newPage, pageSize) {
                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            POListShortChange(gridApi) {
                this.DoSearch();
            }

            POListFilterChange(gridApi: uiGrid.IGridApi) {
                _.forEach(gridApi.grid.columns, (c) => {
                    if(!c.filters[0].term) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));

                        if(keyValue) {
                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                        }
                    }
                });
                
                this.DoSearch();
            }

            DoSearch() {
                this.$scope.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }

                angular.forEach(this.poSearch, (n, key) => {
                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiPOList) {
                    var grid = this.apiPOList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                var params = {
                    connectSearch: searchObject,
                    numberRecords: this.paginationOptions.pageSize,
                    pageNumber: this.paginationOptions.pageNumber
                }

                this.purchaseOrderService.getPurchaseOrderList().query(params, (data) => {
                    this.$scope.IsLoading = false;
                    this.gvwPOList.data = data;

                    if (data[0]) {
                        this.gvwPOList.totalItems = data[0].NumberRecords;
                    } else {
                        this.gvwPOList.totalItems = 0;
                    }

                    this.getFilters();
                    this.setFilters();

                    this.$timeout(() => {
                        if (this.selectedPO) {
                            var index = _.findIndex(this.gvwPOList.data, (o) => { return o.Id === this.selectedPO.entity.Id });
                            this.apiPOList.selection.selectRow(this.gvwPOList.data[index]);
                        }
                    });

                }, (data) => {
                    this.$scope.IsLoading = false;
                    this.gvwPOList.data = [];
                    this.gvwPOList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            loadSuppliers(searchText: string) {
                if (this.$rootScope.Entity.Id) {
                    return this.supplierService.getSupplierDropdown(this.$rootScope.Entity.Id, searchText).query(() => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }
                else {
                    return this.entityService.getCurrentEntity().query((resultEntity: interfaces.applicationcore.IEntity) => {
                        return this.supplierService.getSupplierDropdown(resultEntity.Id, searchText).query(() => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }
            }

            loadCurrencies() {
                var defered = this.$q.defer();

                this.currencyService.getCurrencyDropdownList().query((currencyList: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(currencyList);
                }, (errorResponse) => {
                    defered.resolve([]);
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                });
                
                return defered.promise;
            }

            loadStatuses() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.statusService.getStatusDropdownList().query({ type: Enum.EnumStatusType.POStatus }, (result) => {
                    this.statusList = result;
                    defered.resolve(this.statusList);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                    });

                return defered.promise;
            }

            printDocuments() {
                if (this.apiPOList && this.apiPOList.selection.getSelectedRows().length > 0) {
                    var selected = this.apiPOList.selection.getSelectedRows()[0];

                    this.$state.go("auth.PurchaseOrders.Update.Documents", { poId: selected.Id });
                } else {
                    this.generalService.displayMessage("Please select a Purchase Order", Enum.EnumMessageType.Warning);
                }
            }
        };

        angular.module("app").controller("purchaseOrderListCtrl", controllers.purchase.purchaseOrderListCtrl);
    }
}